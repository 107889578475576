// @flow weak
/* eslint-disable react/no-multi-comp */

import { Grid, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";

const styles = theme => ({
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse"
    }
  },
  sticky: {
    top: 16,
    position: "sticky"
  },
  stickyMobile: {
    top: -180,
    position: "sticky"
  }
});

class Page extends Component {
  render() {
    const {
      children,
      right,
      classes,
      isRightSticky,
      gridContainer
    } = this.props;
    return (
      <div style={{ padding: 16 }}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={8}>
            {children}
          </Grid>
          <Grid
            className={isRightSticky ? classes.stickyMobile : ""}
            item
            sm={4}
            xs={12}
          >
            <div className={isRightSticky ? classes.sticky : ""}>{right}</div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(observer(Page));
