// @flow weak
/* eslint-disable react/no-multi-comp */

import { Input, List, Paper, Grid, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { computed, extendObservable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../components/Auth";
import State from "../State";
import Standings from "../components/Standings";
import ShareLeague from "../components/ShareLeague";
import JoinLeague from "../components/JoinLeague";
import RenewLeague from "../components/RenewLeague";
import Breadcrumbs from "../components/Breadcrumbs";
import Page from "../components/Page";
import MyLeagues from "../components/MyLeagues";
import { withCookies, Cookies } from "react-cookie";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "../components/Card";
import Welcome from "../components/Welcome";
import Forum from "../components/Forum";
import LeagueWinner from "../components/LeagueWinner";
import LeagueBanner from "../components/LeagueBanner";

const styles = (theme) => ({
  paper: {
    overflowX: "auto",
  },
  division: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingLeft: "24px",
    fontWeight: "bold",
  },
});

class League extends Component {
  constructor(props) {
    super(props);

    extendObservable(this, {
      get selectedSeasonId() {
        const seasonId = parseInt(
          this.props.match.params.seasonId || State.currentSeason
        );
        return seasonId;
      },
      get selectedSeason() {
        return (
          this.state.seasons.find(
            (season) => season.season_id == this.selectedSeasonId
          ) || {}
        );
      },
      get userInLeague() {
        return this.state.users.find((user) => {
          return user.user_id == State.user.id;
        });
      },

      get isAdmin() {
        const userInLeague = this.userInLeague;
        return userInLeague && userInLeague.is_admin;
      },

      get needsRenewal() {
        return !this.state.seasons.find(
          (season) => season.season_id == State.currentSeason
        );
      },
    });
  }

  state = {
    users: [],
    league: {},
    seasons: [],
  };

  loadLeague = (shouldResetStandings) => {
    const { cookies, history } = this.props;
    let leagueCode = this.props.match.params.leagueId;
    if (!leagueCode) {
      leagueCode = cookies.get("lastLeagueCode");
      if (leagueCode) history.push("/leagues/" + leagueCode);
      return;
    }

    if (shouldResetStandings) {
      this.setState({
        loaded: false,
      });
    }

    State.getLeague(leagueCode, this.selectedSeasonId, (league) => {
      if (league) {
        this.setState({
          league: league,
        });
        State.activeLeague = { ...league, seasonId: this.selectedSeasonId };
        State.getLeagueSeasons(league.id, (seasons) => {
          console.log("season", seasons);

          this.setState({
            seasons: seasons,
          });

          if (this.state.seasons.length) {
            if (
              !this.state.seasons.find(
                (season) => season.season_id == this.selectedSeasonId
              )
            ) {
              this.handleSeasonChange(
                null,
                this.state.seasons[this.state.seasons.length - 1].season_id
              );
            }
          }
        });
        cookies.set("lastLeagueCode", leagueCode, { path: "/" });
        State.getUsersInLeague(league.id, this.selectedSeasonId, (users) => {
          this.setState({
            loaded: true,
            users: users,
          });
        });
      }
    });
  };

  componentDidUpdate(newProps) {
    if (this.props.location !== newProps.location) {
      this.setState({
        loaded: false,
        users: [],
      });
      this.loadLeague();
    }
  }

  componentWillMount() {
    console.log("component will mount");
    State.addAuthCallback(this.loadLeague, true);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  renderUsers = () => {
    let users = this.state.users.map((user) => {
      return <div>{user.username}</div>;
    });

    return <List>{users}</List>;
  };

  handleSeasonChange = (event, value) => {
    const { cookies, history } = this.props;
    if (value == State.currentSeason) {
      history.replace(`/leagues/${this.state.league.code}`);
    } else {
      history.replace(`/leagues/${this.state.league.code}/${value}`);
    }
    // this.setState({
    //   selectedSeason: value,
    // });
    this.loadLeague(true);
  };

  render() {
    console.log(
      "show join league",
      State.user.loggedIn,
      this.props.match.params.leagueId,
      this.state.loaded,
      !this.userInLeague,
      this.state.league.season_id == State.currentSeason
    );
    return (
      <Page
        right={
          <>
            {State.user.loggedIn &&
            this.props.match.params.leagueId &&
            this.state.loaded &&
            this.isAdmin &&
            this.needsRenewal ? (
              <RenewLeague
                loadLeague={this.loadLeague}
                league={this.state.league}
              />
            ) : null}
            {State.user.loggedIn && this.props.match.params.leagueId && (
              <ShareLeague league={this.state.league} />
            )}
            {State.user.loggedIn &&
              this.props.match.params.leagueId &&
              this.state.loaded &&
              !this.userInLeague &&
              this.selectedSeasonId == State.currentSeason &&
              !State.league.isLocked && (
                <JoinLeague
                  league={this.state.league}
                  loadLeague={this.loadLeague}
                />
              )}
            {State.user.loggedIn && <MyLeagues league={this.state.league} />}
            {!State.user.loggedIn && (
              <Card
                title={
                  this.props.match.params.leagueId
                    ? "Want to Join?"
                    : "Sign In to Join a League"
                }
              >
                <Auth />
              </Card>
            )}
          </>
        }
      >
        {State.user.loaded && !State.user.loggedIn && (
          <Welcome
            title={"Welcome to Win Totals!"}
            body={
              <>
                {" "}
                <p>
                  The Standings page will be your home for tracking you and your
                  league members' NBA record predictions throughout the season.
                  If you haven't entered your win totals yet, navigate to the
                  predictions tab to fill out the standings.
                </p>
                <p>
                  Don't know of a league? Create one and share the league link
                  with other NBA fans you know so you can compete this season.
                  Last day to make predictions is Tuesday, 10/19. Good luck!
                </p>
              </>
            }
          />
        )}
        {!this.props.match.params.leagueId ? (
          State.user.loaded &&
          State.user.loggedIn && (
            <Paper style={{ padding: 16 }}>
              {/* <Breadcrumbs trail={[{ label: "Standings" }]}></Breadcrumbs> */}
              <Typography type="subtitle1">
                Create or Join a league to compete aginst friends!
              </Typography>
            </Paper>
          )
        ) : (
          <>
            <Paper style={{ padding: "8px 8px 16px 16px", overflow: "hidden" }}>
              <LeagueBanner
                isAdmin={this.isAdmin}
                users={this.state.users}
                league={this.state.league}
              />
              <div
                style={{
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 16,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Breadcrumbs
                  trail={[
                    { label: "Standings" },
                    // {
                    //   label: <b>{this.state.league.name}</b> || (
                    //     <Skeleton variant="rect" width={100} height={30} />
                    //   ),
                    // },
                  ]}
                />

                <Tabs
                  variant="scrollable"
                  indicatorColor="primary"
                  value={this.selectedSeasonId}
                  onChange={this.handleSeasonChange}
                >
                  {this.state.seasons.map((season) => (
                    <Tab value={season.season_id} label={season.name} />
                  ))}
                </Tabs>
              </div>

              {this.state.loaded &&
              this.state.users.length &&
              this.selectedSeason.isFinished ? (
                <LeagueWinner
                  league={this.state.league}
                  users={this.state.users}
                />
              ) : null}

              {/* <br />
              {State.user.loaded && !State.user.loggedIn && (
                <div>
                  <Typography type="headline">
                    Sign in to view this league
                  </Typography>
                </div>
              )} */}

              <Standings
                state={this.state}
                users={this.state.users}
                isFinished={this.selectedSeason.isFinished}
              />
              {/* {State.user.loggedIn && !this.state.league.isPublic ? (
                <div>
                  {/* <Typography type="subheading">
                    <b>
                      Your “Picks” will be used to compete in this league.
                      <br />
                      In-season Standings and more to come in November!
                    </b>
                  </Typography>

                  <br />
                  <br />
                  <br /> 
                 
                </div>
              ) : (
                this.state.loaded && ( */}

              {/* <div>
                <Typography type="subheading">
                  <b>
                    You do not have access to this league or it does not exist.
                    Please return to the “Leagues” home page to join or create a
                    league.
                  </b>
                </Typography>
                <br />
              </div> */}

              {/* )
              )} */}
            </Paper>
          </>
        )}
        {/* {State.user.loaded && State.user.loggedIn && (
          <Forum
            article={{
              id: this.state.league.id,
              url:
                "https://www.wintotals.com/standings/" + this.state.league.id,
              title: this.state.league.name
            }}
          />
        )} */}
      </Page>
    );
  }
}

export default withCookies(withStyles(styles)(observer(League)));
