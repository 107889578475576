// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import keycode from "keycode";
import { computed, extendObservable } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Auth from "../components/Auth";
import Progress from "../components/Progress";
import ExpertPicks from "../components/ExpertPicks";

import UserRow from "../components/UserRow";
import Skeleton from "@material-ui/lab/Skeleton";

import State from "../State";

const styles = (theme) => ({
  header: {
    margin: 16,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  paper: {
    overflowX: "auto",
  },
  division: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingLeft: "16px",
    fontWeight: "bold",
    borderBottom: 0,
    height: 45,
    whiteSpace: "nowrap",
  },
  tabs: {
    marginTop: -8,
    marginRight: -8,
    height: 50,
  },
  table: {
    tableLayout: "fixed",
    overflowWrap: "break-word",
  },
});

class LeagueWinner extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    selected: [],
    data: {},
  };

  componentDidMount() {
    setInterval(function () {
      document.querySelectorAll(".award").forEach((el) => el.play());
    }, 5000);
  }

  render() {
    const { users, league, classes } = this.props;
    return (
      <div
        style={{
          marginBottom: 16,
          padding: 16,
          paddingTop: 16,
          paddingBottom: 0,
        }}
      >
        {/* <h2 style={{ marginBottom: 32 }}>
          🎉 2019-2020 {league.name} Winners 🎉
        </h2> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {[
            { rank: 2, label: "2nd", size: 80, fontSize: 18 },
            { rank: 1, label: "1st", size: 100, fontSize: 18 },
            { rank: 3, label: "3rd", size: 80, fontSize: 18 },
          ].map((rank, index) => {
            const winners = users
              .sort((a, b) => a.score - b.score)
              .filter((user) => user.rank == rank.rank);
            return (
              winners.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "center",
                    borderRight: index < 2 ? "1px solid #eee" : "0",
                    flex: 1,
                    padding: 16,
                  }}
                >
                  <h3 style={{ marginTop: 0, marginBottom: 8 }}>
                    {rank.label} Place{" "}
                    <span style={{ fontWeight: "normal" }}>
                      ({winners[0].score} points)
                    </span>
                  </h3>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {winners.map((user, index) => (
                      <>
                        <Button
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            fontWeight: "normal",
                            fontSize: rank.fontSize,
                          }}
                          onClick={() => {
                            State.openModals.user = user.user_id;
                            State.openModals.username = user.username;
                          }}
                        >
                          {user.username}
                        </Button>
                        {index < winners.length - 1 && (
                          <span style={{ color: "#eee" }}>|</span>
                        )}
                      </>
                    ))}
                  </div>
                  <div style={{ fontSize: rank.size }}>
                    {rank.rank == 1 && "🥇"}
                    {rank.rank == 2 && "🥈"}
                    {rank.rank == 3 && "🥉"}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(observer(LeagueWinner));
