import React from "react";
import State from "../State";
import {
  LinearProgress,
  Paper,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { autorun } from "mobx";
import { observer } from "mobx-react";
import Card from "./Card";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  button: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
});

class MyLeagues extends React.Component {
  state = {
    leagues: [],
    loaded: false,
    activeSeason: 5,
    showPastLeagues: false,
  };

  constructor(props) {
    super(props);
    State.updateMyLeagues();
  }

  // init = autorun(() => {});

  createLeague = () => {
    State.openModal(1);
  };

  joinLeague = () => {
    State.openModal(2);
  };

  render() {
    const { classes, league } = this.props;

    const myLeagues = State.myLeagues.leagues.filter(
      (listLeague) => listLeague.season_id == this.state.activeSeason
    );

    const myPastLeagues = State.myLeagues.leagues.filter(
      (listLeague) =>
        listLeague.season_id == this.state.activeSeason - 1 &&
        !myLeagues.find((league) => listLeague.id == league.id)
    );

    const myArchivedLeagues = State.myLeagues.leagues.filter(
      (listLeague, leagueIndex) =>
        listLeague.season_id < this.state.activeSeason - 1 &&
        !myLeagues.find((league) => listLeague.id == league.id) &&
        !myPastLeagues.find((league) => listLeague.id == league.id) &&
        State.myLeagues.leagues.findIndex(
          (existingLeague) => existingLeague.id == listLeague.id
        ) == leagueIndex
    );

    return (
      <Card
        title={"My Leagues"}
        titleActions={
          <div>
            <Button size="small" onClick={this.createLeague}>
              Create
            </Button>
            <Button size="small" onClick={this.joinLeague}>
              Join
            </Button>
          </div>
        }
      >
        {State.myLeagues.loaded && !State.myLeagues.leagues.length && (
          <Typography variant="body2">
            You have not joined any leagues
          </Typography>
        )}
        {!State.myLeagues.loaded && <Skeleton variant="text" height={25} />}
        {myLeagues.map((listLeague) => {
          return (
            <div>
              <Link to={`/leagues/${listLeague.code}`}>
                <Typography type="subheading" align="left">
                  {league && league.code == listLeague.code ? (
                    <b>{listLeague.name}</b>
                  ) : (
                    listLeague.name
                  )}
                </Typography>
              </Link>
            </div>
          );
        })}
        {[myPastLeagues, myArchivedLeagues].map((leagues, leagueIndex) => (
          <>
            {leagues.length > 0 && (
              <div
                style={{
                  textAlign: "left",
                  fontSize: 18,
                  paddingTop: 16,
                  paddingBottom: 8,
                  fontWeight: "bold",
                  cursor: leagueIndex == 1 ? "pointer" : "auto",
                }}
                onClick={() => {
                  if (leagueIndex == 1)
                    this.setState({
                      showPastLeagues: !this.state.showPastLeagues,
                    });
                }}
              >
                {leagueIndex == 0 ? "2022 / 2023 Leagues" : "Past Leagues"}{" "}
                {leagueIndex == 1
                  ? "[" + (this.state.showPastLeagues ? "-" : "+") + "]"
                  : ""}
              </div>
            )}
            <div
              style={{
                paddingTop: 8,
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                display:
                  leagueIndex != 1 || this.state.showPastLeagues
                    ? "block"
                    : "none",
              }}
            >
              {leagues.map((listLeague) => {
                return (
                  <div>
                    <Link
                      to={`/leagues/${listLeague.code}/${listLeague.season_id}`}
                    >
                      <Typography type="subheading" align="left">
                        {league && league.code == listLeague.code ? (
                          <b>{listLeague.name}</b>
                        ) : (
                          listLeague.name
                        )}
                      </Typography>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        ))}
      </Card>
    );
  }
}

export default withStyles(styles)(observer(MyLeagues));
