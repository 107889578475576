// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import keycode from "keycode";
import { computed, extendObservable, runInAction } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Auth from "../components/Auth";
import Progress from "../components/Progress";
import ExpertPicks from "../components/ExpertPicks";
import Page from "../components/Page";
import TeamRow from "../components/TeamRow";
import State from "../State";
import Welcome from "../components/Welcome";
let counter = 0;

const columnData = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Team",
  },
  { id: "wins", numeric: true, disablePadding: false, label: "Wins" },
  { id: "losses", numeric: true, disablePadding: false, label: "Losses" },
  { id: "pct", numeric: true, disablePadding: false, label: "Pct" },
  { id: "score", numeric: true, disablePadding: false, label: "Score" },
];

const responseFacebook = (response) => {
  State.createUser(response.name, response.email, response.accessToken);
  //localstorage.setItem("accessToken", response.accessToken);
};

const tableHeadStyles = (theme) => ({
  name: { paddingRight: 0 },
  pct: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  score: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wins: {
    paddingRight: 0,
  },
  losses: {
    // [theme.breakpoints.down("xs")]: {
    //   display: "none"
    // }
  },
});

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: 2,
  },
  // highlight:
  //   theme.palette.type === "light"
  //     ? {
  //         color: theme.palette.accent.A700,
  //         backgroundColor: theme.palette.accent.A100
  //       }
  //     : {
  //         color: theme.palette.accent.A100,
  //         backgroundColor: theme.palette.accent.A700
  //       },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

const styles = (theme) => ({
  header: {
    margin: 16,
    display: "flex",
    justifyContent: "space-between",
  },

  paper: {
    overflowX: "auto",
  },
  division: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingLeft: "16px",
    fontWeight: "bold",
    borderBottom: 0,
    height: 45,
    whiteSpace: "nowrap",
  },
  tabs: {
    marginTop: -8,
    marginRight: -8,
    height: 50,
  },
  conference: {
    minWidth: "0px",
  },
  hideMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  table: {
    // tableLayout: "fixed",
  },
  title: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  userTitle: {
    margin: 16,
    fontSize: 20,
  },
});

class Picks extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    selected: [],
    data: {},
  };

  constructor(props) {
    super(props);
    if (this.props.userId) {
      State.loadUserTeamPicks(
        this.props.userId,
        this.props.seasonId,
        (picks) => {
          this.userPicks = picks;
        }
      );
    }
    extendObservable(this, {
      userPicks: new Map(),
      picks: computed(() => {
        if (this.props.userId) {
          return this.userPicks;
        } else {
          return State.userTeamPicks;
        }
      }),
      // winBalance: computed(() => {
      //   var teams = Array.from(State.userTeamPicks.values());
      //   return (
      //     teams.length * 41 -
      //    this.totalWins
      //   );
      // }),

      sortedTeams: computed(() => {
        if (State.updateSortedPicks) {
          console.log("sorted picks");
        }
        let results = Array.from(this.picks.keys())
          .sort((a, b) => {
            return runInAction(() => {
              if (this.state.order == "asc") {
                [a, b] = [b, a];
              }
              switch (this.state.orderBy) {
                case "name":
                  return this.picks
                    .get(b)
                    .city.localeCompare(this.picks.get(a).city);
                  break;
                case "losses":
                  return (
                    State.totalGames -
                    this.picks.get(b).wins -
                    (State.totalGames - this.picks.get(a).wins)
                  );
                  break;
                case "pct":
                  return (
                    this.picks.get(b).wins / State.totalGames -
                    this.picks.get(a).wins / State.totalGames
                  );
                  break;
              }
              return (
                this.picks.get(b)[this.state.orderBy] -
                this.picks.get(a)[this.state.orderBy]
              );
            });
          })
          .filter((teamId) => {
            let team = this.picks.get(teamId);
            if (State.picker.conf == 0) {
              return (
                team.division_id == "ATL" ||
                team.division_id == "CEN" ||
                team.division_id == "SE"
              );
            } else if (State.picker.conf == 1) {
              return (
                team.division_id == "SW" ||
                team.division_id == "NW" ||
                team.division_id == "PAC"
              );
            } else {
              return true;
            }
          });
        if (this.state.orderBy == "name") {
          results
            .sort((a, b) => {
              return this.picks
                .get(b)
                .division_id.localeCompare(this.picks.get(a).division_id);
            })
            .reverse();
          // results.forEach((teamId)=>{

          // });
        }
        return results;
      }),
    });
  }

  componentDidMount() {
    // State.getAllUserTeamPicks();
  }

  updatePick = (teamId, value) => {
    State.updatePick(teamId, value);
    // this.setState({
    //   data[teamId]: event.target.value
    // });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map((n) => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };

  updateConference = (event, id) => {
    State.picker.conf = id;
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const classes = this.props.classes;
    const { data, order, orderBy, selected } = this.state;
    const { username, userId, seasonId } = this.props;

    return (
      <Paper className={classes.paper}>
        {userId && username && (
          <Typography className={classes.userTitle} variant="h6">
            {username + "'s Predictions"}
          </Typography>
        )}
        <div className={classes.header}>
          {!userId && (
            <Typography className={classes.title} variant="h6">
              Make your Predictions
            </Typography>
          )}
          <Tabs
            className={classes.tabs}
            value={State.picker.conf}
            indicatorColor="primary"
            onChange={this.updateConference}
          >
            <Tab
              value={0}
              className={classes.conference}
              label={
                <span>
                  Eastern <span className={classes.hideMobile}>Conference</span>
                </span>
              }
            />
            <Tab
              value={1}
              className={classes.conference}
              label={
                <span>
                  Western <span className={classes.hideMobile}>Conference</span>
                </span>
              }
            />
            <Tab
              value={2}
              className={classes.conference}
              label={<span>NBA</span>}
            />
          </Tabs>
        </div>
        <Table className={classes.table} size="small">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={this.handleSelectAllClick}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {!this.sortedTeams.length && (
              <TeamRow
                // key={teamId}
                // isSelected={isSelected}
                // handleKeyDown={this.handleKeyDown}
                // updatePick={this.updatePick}
                team={{ id: "skeleton" }}
              />
            )}
            {this.sortedTeams.map((teamId) => {
              let team = this.picks.get(teamId);

              const isSelected = this.isSelected(teamId);
              let res = [];
              if (this.state.orderBy == "name") {
                if (!this.last || team.division_id != this.last.division_id) {
                  res.push(
                    <TableRow key={team.division_id}>
                      <TableCell className={classes.division}>
                        {State.divisions[team.division_id]} Division
                      </TableCell>
                    </TableRow>
                  );
                }
                this.last = team;
              }
              res.push(
                <TeamRow
                  key={teamId}
                  isSelected={isSelected}
                  handleKeyDown={this.handleKeyDown}
                  updatePick={this.updatePick}
                  team={team}
                />
              );
              return res;
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(observer(Picks));

class EnhancedTableHeader extends Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      classes,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map((column) => {
            if (column.id == "score" && !State.league.isLocked) return null;
            return (
              <TableCell
                className={classes[column.id]}
                key={column.id}
                align={column.numeric ? "right" : "left"}
                style={column.style}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            );
          }, this)}
          {/* <TableCell checkbox>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < 5}
                checked={numSelected === 5}
                onChange={onSelectAllClick}
              />
            </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }
}

const EnhancedTableHead = withStyles(tableHeadStyles)(EnhancedTableHeader);
