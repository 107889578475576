// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Input,
  TableCell,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import React from "react";
import State from "../State";
import { observer } from "mobx-react";
import Skeleton from "@material-ui/lab/Skeleton";
import * as NBAIcons from "react-nba-logos";

const styles = (theme) => ({
  city: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  name: {
    paddingRight: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "300px",
  },
  input: {
    margin: 0,
    fontWeight: 500,
    width: 50,
  },
  nativeInput: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      margin: 0,
    },
    "&[type=number]": {
      "-webkit-appearance": "textfield",
      "-moz-appearance": "textfield",
    },
  },
  filledInput: {
    borderRadius: 0,
  },
  wins: {
    paddingRight: 0,
  },
  losses: {
    // [theme.breakpoints.down("xs")]: {
    //   display: "none"
    // }
  },
  percent: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  score: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  icon: {
    verticalAlign: "inherit",
    display: "inline-block",
    marginRight: 12,
  },
  row: {
    // height: 58,
  },
});

class TeamRow extends React.Component {
  render() {
    const { team, handleKeyDown, updatePick, isSelected, classes } = this.props;

    let TeamLogo;

    if (team.shortName) {
      TeamLogo = NBAIcons[team.shortName.substr(0, 3).toUpperCase()];
    }

    return (
      <TableRow
        hover
        /* onClick={event => this.handleClick(event, n.id)} */
        onKeyDown={(event) => handleKeyDown(event, team.team_id)}
        role="checkbox"
        aria-checked={isSelected}
        tabIndex="-1"
        key={team.team_id}
        className={classes.row}
        selected={isSelected}
      >
        <TableCell className={classes.name}>
          {team.city && TeamLogo && (
            <div className={classes.icon}>
              <TeamLogo size={36} />
            </div>
          )}
          {team.city ? (
            <>
              <span className={classes.city}>{team.city + " "}</span>
              {team.name}
            </>
          ) : (
            <Skeleton variant="text" height={25} />
          )}
        </TableCell>
        <TableCell align="right" className={classes.wins}>
          {team.city && (
            <TextField
              margin="dense"
              type="number"
              disabled={State.league.isLocked}
              InputProps={{
                classes: {
                  root: classes.filledInput,
                },
              }}
              inputProps={{
                className: classes.nativeInput,
                pattern: "d*",
                maxLength: 2,
                size: 2,
                style: { borderRadius: 0, textAlign: "right" },
              }}
              classes={{ root: classes.input }}
              onChange={(event) => updatePick(team.team_id, event.target.value)}
              onBlur={() => {
                State.updateSortedPicks++;
              }}
              placeholder={team.placeholderWins}
              value={team.wins}
              hiddenLabel
              variant="filled"
            />
          )}
        </TableCell>
        <TableCell className={classes.losses} align="right">
          {team.city && State.totalGames - team.wins}
        </TableCell>
        <TableCell className={classes.percent} align="right">
          {team.city && ((team.wins / State.totalGames) * 100).toFixed(1)}
        </TableCell>
        {State.league.isLocked && (
          <TableCell className={classes.score} align="right">
            {team.score}
          </TableCell>
        )}
        {/* <TableCell checkbox>
        <Checkbox
          onClick={event => this.handleClick(event, team.id)}
          checked={isSelected}
        />
      </TableCell> */}
      </TableRow>
    );
  }
}

export default withStyles(styles)(observer(TeamRow));
