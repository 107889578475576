import { observer } from "mobx-react";
import {
  observable,
  computed,
  transaction,
  action,
  autorun,
  extendObservable,
} from "mobx";
import axios from "axios";
import firebase from "firebase";
import firebaseui from "firebaseui";

class State {
  constructor(props) {
    extendObservable(this, {
      savingPicks: false,
      updateSortedPicks: 0,
      currentSeason: 6,
      winStats: computed(() => {
        var teams = Array.from(this.userTeamPicks.values());
        var totalWins = teams.reduce((totalWins, team) => {
          return totalWins + parseInt(team.wins || 0);
        }, 0);
        var numTeams = teams.length * (this.totalGames / 2);
        return [totalWins, numTeams, (totalWins / numTeams) * 100];
      }),
      // winBalance: computed(() => {
      //   var teams = Array.from(State.userTeamPicks.values());
      //   return (
      //     teams.length * 41 -
      //    this.totalWins
      //   );
      // }),
      // location: computed(() => {
      //   console.log(window.location);
      //   let path = window.location.pathname.split("/")[1];
      //   if (!path) {
      //     path = "picks";
      //   }
      //   return path;
      // })
    });
  }

  teams = [];

  totalGames = 82;

  requestQueue = [];

  authCallbacks = [];

  user = observable({
    id: null,
    loaded: false,
    loggedIn: false,
  });

  openModals = observable({
    user: null,
    username: null,
    seasonId: null,
  });

  activeLeague = null;

  picker = observable({
    conf: 0,
  });

  leagues = observable({
    dialogOpen: 0,
  });

  myLeagues = observable({
    leagues: [],
    loaded: false,
  });

  dialog = observable(0);

  drawer = observable({
    open: false,
  });

  toggleDrawer = () => {
    this.drawer.open = !this.drawer.open;
  };

  // winStats = computed(() => {
  //   var teams = Array.from(this.userTeamPicks.values());
  //   var totalWins = teams.reduce((totalWins, team) => {
  //     return totalWins + parseInt(team.wins || 0);
  //   }, 0);
  //   var numTeams = teams.length * 41;
  //   return [totalWins, numTeams, (totalWins / numTeams) * 100];
  // });

  divisions = {
    SE: "Southeast",
    CEN: "Central",
    ATL: "Atlantic",
    SW: "Southwest",
    PAC: "Pacific",
    NW: "Northwest",
  };

  apiUrl = "https://us-central1-win-totals.cloudfunctions.net/handler/";
  // apiUrl = "http://localhost:8010/win-totals/us-central1/handler/";
  userTeamPicks = observable.map();
  myScorePeriod = observable.map();
  league = observable({
    isLocked: false,
  });

  firebaseConfig = {
    apiKey: "AIzaSyDRMWyJG7Uoit6LHfAHWO2g-2_4KfN0_Yc",
    authDomain: "wintotals.firebaseapp.com",
    databaseURL: "https://wintotals.firebaseio.com",
    projectId: "wintotals",
    storageBucket: "wintotals.appspot.com",
    messagingSenderId: "628713486531",
  };

  uiConfig = {
    // signInSuccessUrl: '<url-to-redirect-to-on-success>',
    callbacks: {
      signInSuccess: function (currentUser, credential, redirectUrl) {
        // Do something.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        window.gtag("event", "login");
        return false;
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        // document.getElementById('loader').style.display = 'none';
      },
    },
    signInFlow: "popup",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
    tosUrl: "http://www.wintotals.com/tos.html",
  };

  updateMyLeagues = () => {
    this.addAuthCallback(() => {
      this.getLeagues((leagues) => {
        transaction(() => {
          console.log("got leagues", leagues);

          this.myLeagues.leagues.replace(leagues);
          this.myLeagues.loaded = true;
        });
      });
    });
  };

  getAllTeams = (callback) => {
    if (this.teams.length > 0) {
      callback(this.teams);
    } else {
      axios.get(this.apiUrl + "getAllTeams").then((res) => {
        this.teams = res.data.map((team) => {
          team.team_id = team.id;
          return team;
        });
        callback(this.teams);
      });
    }
  };

  addAuthCallback = (callback, runAlways) => {
    if (this.user.loggedIn || runAlways) {
      callback();
    }
    if (!this.user.loggedIn) {
      this.authCallbacks.push(callback);
    }
  };

  runAuthCallbacks = () => {
    this.authCallbacks.forEach((callback) => {
      callback();
    });
    this.authCallbacks = [];
  };

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          // this.getAllUserTeamPicks();
          this.user.loggedIn = false;
        },
        function (error) {
          // An error happened.
        }
      );
  };

  getUserScorePeriod = (periodId) => {
    axios
      .get(this.apiUrl + "getUserScorePeriod", {
        userId: 1,
        periodId: periodId,
      })
      .then((res) => {
        var periods = res.body;
        periods.forEach((periodId, score) => {
          this.myScorePeriod.set(periodId, score);
        });
      });
  };

  saveExistingPicks = (timestamp) => {
    let isUpdating = true;

    const userPicks = this.userTeamPicks.values().map((team) => {
      return {
        teamId: team.team_id,
        wins: team.wins,
      };
    });

    if (this.user.loggedIn) {
      setTimeout(() => {
        if (isUpdating) this.savingPicks = true;
      }, 500);

      this.requestQueue.push(() => {
        axios
          .post(this.apiUrl + "updateUserTeamPicks", {
            userId: this.user.id,
            picks: userPicks,
          })
          .then((res) => {
            if (this.requestQueue.length) this.requestQueue.shift()();
            isUpdating = false;
            window.gtag("event", "save-predictions", {
              event_category: "predictions",
            });
            this.savingPicks = false;
          });
      });

      if (this.requestQueue.length) this.requestQueue.shift()();
    }
  };

  getIsLocked = () => {
    axios.post(this.apiUrl + "isLocked").then((res) => {
      console.log("res", res);
      this.league.isLocked = res.data.isLocked;
      this.league.isLockedLoaded = true;
      // this.league.isLocked = true;
    });
  };

  loadUserTeamPicks = (userId, seasonId, callback) => {
    let picks = new Map();
    this.getAllTeams((teams) => {
      teams.forEach((team) => {
        if (team.team_id) {
          team.wins = "";
          team.placeholderWins = 0;
          team.score = "";
          picks.set(team.team_id, team);
        }
      });
      axios
        .post(this.apiUrl + "getAllUserTeamPicks", {
          userId: userId,
          seasonId: seasonId,
        })
        .then((res) => {
          res.data.forEach((team) => {
            if (team.team_id) {
              if (picks.get(team.team_id)) {
                let newTeam = Object.assign(picks.get(team.team_id), team);
                picks.set(team.team_id, newTeam);
              }
            }
          });
          callback(picks);
        });
    });
  };

  getAllUserTeamPicks = (userId, seasonId) => {
    if (!seasonId) seasonId = this.currentSeason;
    console.log("1) called get all user team picks");
    this.getIsLocked();
    this.getAllTeams((teams) => {
      teams.forEach((team) => {
        if (team.team_id) {
          team.wins = "";
          team.score = "";
          team.placeholderWins = 0;
          this.userTeamPicks.set(team.team_id, team);
        }
      });
      if (userId || (this.user.loaded && this.user.id)) {
        axios
          .post(this.apiUrl + "getAllUserTeamPicks", {
            userId: userId || this.user.id,
            seasonId: seasonId,
          })
          .then((res) => {
            console.log("2) loading user picks");
            this.updateSortedTeams++;
            res.data.forEach((team) => {
              if (team.team_id) {
                if (this.userTeamPicks.get(team.team_id)) {
                  let newTeam = Object.assign(
                    this.userTeamPicks.get(team.team_id),
                    team
                  );

                  this.userTeamPicks.set(team.team_id, newTeam);
                }
              }
            });
            this.updateSortedPicks++;
            if (userId && this.user.loaded && this.user.id) {
              this.saveExistingPicks();
            }
            // this.teams = res.data.reduce(function(acc, cur, i) {
            //   acc[cur.team_id] = cur;
            //   return acc;
            // }, {});
          });
      }
    });
  };

  updatePick = (teamId, wins) => {
    if (isNaN(wins)) wins = "";
    if (wins > this.totalGames) wins = this.totalGames;
    if (wins < 0) wins = 0;
    this.userTeamPicks.get(teamId).wins = wins;

    this.saveExistingPicks();

    // axios
    //   .post(this.apiUrl + "updateUserTeamPick", {
    //     userId: this.user.id,
    //     teamId: teamId,
    //     wins: wins
    //   })
    //   .then(res => {
    //     isUpdating = false;
    //     this.savingPicks = false;
    //   });
  };

  joinLeague = (code, callback, errorCallback) => {
    console.log("code", code);
    axios
      .post(this.apiUrl + "joinGroup", {
        userId: this.user.id,
        code: code,
      })
      .then((res) => {
        window.gtag("event", "join-league", {
          event_category: "league",
          value: code,
        });

        this.updateMyLeagues();
        if (callback) callback(res.data.groupId);
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  createLeague = (name, callback) => {
    axios
      .post(this.apiUrl + "createGroup", {
        userId: this.user.id,
        name: name,
      })
      .then((res) => {
        window.gtag("event", "create-league", {
          event_category: "league",
          value: res.data[0].code,
        });
        this.updateMyLeagues();
        console.log("res", res);
        if (callback) callback(res.data[0].code);
      });
  };

  updateLeague = (userId, groupId, league, callback) => {
    axios
      .post(this.apiUrl + "updateGroup", {
        userId: userId,
        groupId: groupId,
        name: league.name,
        description: league.description,
        website: league.website,
        avatarURL: league.avatarURL,
        bannerURL: league.bannerURL,
      })
      .then((res) => {
        this.updateMyLeagues();
        if (callback) callback(res);
      });
  };

  renewLeague = (groupId, callback) => {
    axios
      .post(this.apiUrl + "renewGroup", {
        userId: this.user.id,
        groupId: groupId,
      })
      .then((res) => {
        this.updateMyLeagues();
        if (callback) callback(res.data[0]);
      });
  };

  getLeagues = (callback) => {
    transaction(() => {
      axios
        .post(this.apiUrl + "getGroups", {
          userId: this.user.id,
        })
        .then((res) => {
          if (callback) callback(res.data);
        });
    });
  };

  getLeagueSeasons = (leagueId, callback) => {
    transaction(() => {
      axios
        .post(this.apiUrl + "getGroupSeasons", {
          groupId: leagueId,
        })
        .then((res) => {
          if (callback) callback(res.data);
        });
    });
  };

  getUser = (userId, callback) => {
    axios
      .post(this.apiUrl + "getUser", {
        userId: userId,
      })
      .then((res) => {
        if (callback) callback(res.data[0]);
      });
  };

  getLeague = (leagueCode, seasonId, callback) => {
    axios
      .post(this.apiUrl + "getGroup", {
        groupCode: leagueCode,
        seasonId: seasonId,
      })
      .then((res) => {
        if (callback) {
          const league = res.data[0];
          callback(league);
        }
      });
  };

  getUsersInLeague = (leagueId, seasonId, callback) => {
    this.getIsLocked();
    axios
      .post(this.apiUrl + "getUserGroup", {
        groupId: leagueId,
        seasonId: seasonId,
        periodId: 10,
      })
      .then((res) => {
        if (callback) {
          let users = res.data;
          let rank = 0;
          let lastScore = -1;
          let lastRank = 1;
          let absoluteRank = 0;
          let lastLastScore = -1;
          users = users
            .sort((a, b) => a.lastScore - b.lastScore)
            .map((user, index) => {
              if (user.lastScore > lastLastScore) {
                lastRank = index + 1;

                lastLastScore = user.lastScore;
              }
              user.lastRank = lastRank;
              return user;
            })
            .sort((a, b) => a.score - b.score)
            .map((user, index) => {
              if (user.score > lastScore) {
                rank = index + 1;
                absoluteRank++;
                lastScore = user.score;
              }
              user.username = user.username || "Anonymous";
              user.rank = rank;
              user.absoluteRank = absoluteRank;

              return user;
            });
          callback(users);
        }
      });
  };

  createUser = (username, callback) => {
    axios
      .post(this.apiUrl + "createUser", {
        id: this.user.id,
        username: username,
      })
      .then((res) => {
        if (callback) callback();
      })
      .catch((error) => {
        if (callback) callback();
      });
  };

  updateUser = (user, callback) => {
    if (user && user.id)
      axios
        .post(this.apiUrl + "updateUser", user)
        .then((res) => {
          if (callback) callback();
        })
        .catch((error) => {
          if (callback) callback();
        });
  };

  openModal = (modalId) => {
    this.leagues.dialogOpen = modalId;
  };

  closeModals = () => {
    this.leagues.dialogOpen = 0;
  };
}

var singleton = new State();
window.state = singleton;
export default singleton;
