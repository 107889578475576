import React from "react";
import State from "../State";
import {
  LinearProgress,
  Paper,
  withStyles,
  Button,
  IconButton,
  Input,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { observer } from "mobx-react";
import Card from "./Card";

import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";

const styles = (theme) => ({
  button: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
});

class RenewLeague extends React.Component {
  render() {
    const { classes, loadLeague, league } = this.props;
    return (
      <Card title="Renew League">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            State.renewLeague(league.id, () => {
              loadLeague(true);
            });
          }}
        >
          Renew {league.name} for 24/25 Season
        </Button>
      </Card>
    );
  }
}

export default withStyles(styles)(observer(RenewLeague));
