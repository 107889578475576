// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React from "react";
import State from "../../State";

export default class JoinLeague extends React.Component {
  state = {
    code: "",
    error: "",
    joining: false,
  };

  handleRequestClose = () => {
    this.setState({
      error: "",
      joining: false,
    });
    State.closeModals();
  };

  joinLeague = () => {
    this.setState({
      joining: true,
    });
    State.joinLeague(
      this.state.code,
      (leagueCode) => {
        console.log("leagueId", leagueCode);
        this.handleRequestClose();
        this.props.history.push("/leagues/" + leagueCode);
      },
      () => {
        this.setState({
          joining: false,
          error: "We couldn't find a league with that code.",
        });
      }
    );
  };

  handleChange = (event) => {
    this.setState({ code: event.target.value, error: "" });
  };

  render() {
    return (
      <Dialog
        open={State.leagues.dialogOpen == 2}
        onRequestClose={this.handleRequestClose}
      >
        <DialogTitle>Join League</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the code that was provided to you by an active member.
          </DialogContentText>
          <TextField
            error={this.state.error}
            autoFocus
            margin="dense"
            id="name"
            label="League Code"
            type="text"
            value={this.state.code}
            onChange={this.handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.joinLeague}
            color="primary"
            disabled={this.state.joining}
          >
            {this.state.joining ? "Joining..." : "Join"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
