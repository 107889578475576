// @flow weak
/* eslint-disable react/no-multi-comp */

import { Input, List, Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../components/Auth";
import State from "../State";
import Standings from "../components/Standings";
import ShareLeague from "../components/ShareLeague";
import JoinLeague from "../components/JoinLeague";
import Breadcrumbs from "../components/Breadcrumbs";
import Page from "../components/Page";
import MyLeagues from "../components/MyLeagues";
import { withCookies, Cookies } from "react-cookie";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "../components/Card";
import Welcome from "../components/Welcome";
import Disqus from "disqus-react";

const styles = theme => ({
  paper: {
    padding: "8px 16px",
    marginTop: 16,
    display: "flex",
    // height: "calc(100vh - 100px)",
    marginLeft: 16,
    marginRight: 16
  }
});

class Blog extends Component {
  componentDidMount() {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "http://45.79.56.59/");
    xhr.onload = function() {
      if (xhr.status === 200) {
        this.setState({
          forum: xhr.responseText
        });
      } else {
        alert("Request failed.  Returned status of " + xhr.status);
      }
    };
    xhr.send();
  }

  render() {
    const { classes } = this.props;
    const disqusShortname = "wintotals";

    console.log("props", this.props);

    // const disqusConfig = {
    //   url: this.props.article.url,
    //   identifier: this.props.article.id,
    //   title: this.props.article.title
    // };

    return (
      <Paper className={classes.paper}>
        {/* <h1>{this.props.article.title}</h1> */}
        {/* <Disqus.CommentCount shortname={disqusShortname} config={disqusConfig}>
          Comments
        </Disqus.CommentCount> */}

        {/* <p>{this.props.article.body}</p> */}

        {/* <Disqus.CommentEmbed
          commentId={this.props.article.featuredComment}
          showMedia={true}
          height={160}
        /> */}

        {/* <Disqus.DiscussionEmbed
          shortname={disqusShortname}
          config={disqusConfig}
        /> */}
        <iframe
          scrolling="no"
          style={{
            overflow: "hidden",
            width: "100%",
            height: "500px",
            border: "0px"
          }}
          src="http://45.79.56.59/"
        />
      </Paper>
    );
  }
}

export default withCookies(withStyles(styles)(observer(Blog)));
