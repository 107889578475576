import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import State from "../State";

class Auth extends React.Component {
  componentDidMount() {}

  loginSMS() {}

  renderWelcome() {
    return (
      <div style={{ paddingTop: "10px" }}>
        <Button onClick={State.logout}>Logout</Button>
      </div>
    );
  }

  render() {
    if (State.user.loaded) {
      if (State.user.loggedIn) {
        return null;
        return this.renderWelcome();
      } else {
        return !State.user.loggedIn && <FirebaseUI></FirebaseUI>;
      }
    } else {
      return null;
    }
  }
}

class FirebaseUI extends React.Component {
  componentDidMount() {
    State.authUi.start("#firebaseui-auth", State.uiConfig);
  }

  componentWillUnmount() {
    State.authUi.reset();
  }

  render() {
    return <div id="firebaseui-auth"></div>;
  }
}

export default observer(Auth);
