import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/styles";

import firebase from "firebase";
import firebaseui from "firebaseui";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar";
import League from "./pages/League";
import Leagues from "./pages/Leagues";
import Picker from "./pages/Picker";
import State from "./State";
import theme from "./components/Theme";
import ModalManager from "./components/Modals/ModalManager";
import { transaction } from "mobx";
import Blog from "./components/Blog";
import UserModal from "./components/Modals/UserModal";
import EmailBanner from "./components/EmailBanner";
import Privacy from "./pages/Privacy";

class CreateUser extends React.Component {
  state = {
    username: "",
  };

  handleRequestClose = () => {
    this.setState({ username: "" });
    State.leagues.dialogOpen = 0;
  };

  createUser = () => {
    State.createUser(this.state.username, () => {
      State.leagues.dialogOpen = 0;
    });
  };

  handleChange = (event) => {
    this.setState({ username: event.target.value });
  };

  render() {
    return (
      <Dialog
        open={State.leagues.dialogOpen == 3}
        onRequestClose={this.handleRequestClose}
      >
        <DialogTitle>Pick a username</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occationally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Username"
            type="text"
            value={this.state.username}
            onChange={this.handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.createUser} color="primary">
            Go!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class App extends Component {
  componentWillMount() {
    window.test = () => {
      State.leagues.dialogOpen = 3;
    };
    window.test2 = () => {
      return State.leagues.dialogOpen;
    };
    // State.getAllUserTeamPicks();
    firebase.initializeApp(State.firebaseConfig);
    State.authUi = new firebaseui.auth.AuthUI(firebase.auth());
    firebase.auth().onAuthStateChanged((user) => {
      transaction(() => {
        console.log("state changed");

        if (user) {
          console.log("USER", user);

          window.gtag("config", "GA_MEASUREMENT_ID", {
            user_id: user.uid,
          });

          State.updateUser({ ...user.toJSON(), id: user.uid });
          State.addAuthCallback(() => {
            State.getUser(State.user.id, (user) => {
              if (!user || !user.username) {
                State.saveExistingPicks();
                State.leagues.dialogOpen = 3;

                window.gtag("event", "sign_up");

                // ga('send', {
                //   hitType: 'event',
                //   eventCategory: 'Videos',
                //   eventAction: 'play',
                //   eventLabel: 'Fall Campaign'
                // });
              } else {
                State.getAllUserTeamPicks();
              }
            });
          });
          State.user.loggedIn = true;
          State.user.id = user.uid;

          State.runAuthCallbacks();
        } else {
          State.user.loggedIn = false;
          State.user.id = null;
          State.runAuthCallbacks();
          State.getAllUserTeamPicks();
        }
        State.user.loaded = true;
      });
    });
  }
  render() {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div className="App container">
            <CreateUser open={State.leagues.dialogOpen == 3} />
            <ModalManager />
            <div className="container">
              {/* {State.user.loaded && !State.user.loggedIn && <EmailBanner />} */}
              <Route exact path="/" component={Picker} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/leagues" component={League} />
              <Route path="/leagues/:leagueId/:seasonId?" component={League} />
              {/* <Route path="/user/:userId" component={User} /> */}
              <Route path="/blog" component={Blog} />
            </div>
          </div>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}

export default observer(App);
