// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React from "react";
import State from "../../State";

export default class CreateLeague extends React.Component {
  state = {
    name: "",
    joining: false,
    error: "",
  };

  handleRequestClose = () => {
    this.setState({
      joining: false,
      error: "",
    });
    State.closeModals();
  };

  createLeague = () => {
    this.setState({
      joining: true,
    });
    State.createLeague(
      this.state.name,
      (leagueCode) => {
        this.handleRequestClose();
        this.props.history.push("/leagues/" + leagueCode);
      },
      () => {
        this.setState({ joining: false, error: "There was an error." });
      }
    );
  };

  handleChange = (event) => {
    this.setState({ name: event.target.value, error: "" });
  };

  render() {
    return (
      <Dialog
        open={State.leagues.dialogOpen == 1}
        onRequestClose={this.handleRequestClose}
      >
        <DialogTitle>Create League</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To get started, enter a name for your league.
          </DialogContentText>
          <TextField
            autoFocus
            error={this.state.error}
            margin="dense"
            id="name"
            label="League Name"
            type="text"
            value={this.state.name}
            onChange={this.handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.createLeague}
            color="primary"
            disabled={this.state.joining}
          >
            {this.state.joining ? "Creating..." : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
