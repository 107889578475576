// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Input,
  TableCell,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import React from "react";
import State from "../State";
import { observer } from "mobx-react";
import Skeleton from "@material-ui/lab/Skeleton";
import CheckIcon from "@material-ui/icons/Check";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { extendObservable } from "mobx";

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return "st";
  }
  if (j == 2 && k != 12) {
    return "nd";
  }
  if (j == 3 && k != 13) {
    return "rd";
  }
  return "th";
}

const styles = (theme) => ({
  check: {
    fontSize: 20,
    verticalAlign: "middle",
    color: theme.palette.secondary[600],
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 0,

    // display: "flex",
    // alignItems: "center"
  },
  input: {
    margin: 0,
    fontWeight: 500,
  },
  filledInput: {
    borderRadius: 0,
  },
  me: {
    fontWeight: "bold",
  },
  ordinal: {},
  diff: {
    // position: "absolute",
    // textAlign: "right",
    // width: 10,
    // whiteSpace: "nowrap"
  },
  positive: {
    color: "green",
  },
  negative: {
    color: "red",
  },
  rank: {
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    // [theme.breakpoints.down("sm")]: {
    //   // display: "none",
    //   width: "40px !important",
    // },
  },
  trend: {
    fontSize: 14,
    margin: 5,
    "& svg": {
      width: ".5em",
      height: ".5em",
      verticalAlign: "text-bottom",
    },
  },
  tableRow: {
    cursor: "pointer",
  },
});

class UserRow extends React.Component {
  constructor(props) {
    super(props);
    extendObservable(this, {
      get isFinished() {
        return this.props.isFinished;
      },
      get isLocked() {
        console.log("IS FINISHED", this.isFinished);
        return (
          this.isFinished ||
          (State.league.isLocked && State.league.isLockedLoaded)
        );
      },
    });
  }

  renderTrend(rank, lastRank, reverse) {
    const classes = this.props.classes;
    const diff = lastRank - rank;
    if (!diff) return null;
    return (
      <span
        className={
          classes.trend + " " + (diff > 0 ? classes.positive : classes.negative)
        }
      >
        {diff > 0 ? (
          reverse ? (
            "-"
          ) : (
            <ArrowUpward />
          )
        ) : reverse ? (
          "+"
        ) : (
          <ArrowDownward />
        )}
        {Math.abs(diff)}
      </span>
    );
  }
  render() {
    const {
      user,
      handleKeyDown,
      updatePick,
      isSelected,
      classes,
      index,
      state,
    } = this.props;

    if (!user.username)
      return (
        <div>
          <Skeleton variant="text" height={25} />
        </div>
      );

    return (
      <TableRow
        hover={user.season_id != State.currentSeason}
        className={classes.tableRow}
        style={{
          cursor:
            user.season_id != State.currentSeason || State.league.isLocked
              ? "pointer"
              : "auto",
        }}
        /* onClick={event => this.handleClick(event, n.id)} */
        // onKeyDown={event => handleKeyDown(event, user.id)}
        role="checkbox"
        aria-checked={isSelected}
        tabIndex="-1"
        key={user.user_id}
        selected={isSelected}
        onClick={() => {
          console.log("user", user, state);
          if (user.season_id != State.currentSeason || State.league.isLocked) {
            State.openModals.user = user.user_id;
            State.openModals.username = user.username;
            State.openModals.seasonId = user.season_id;
          }
        }}
      >
        <TableCell
          style={{
            width: 80,
            // textAlign: "center"
          }}
          className={
            classes.rank +
            " " +
            (user.user_id == State.user.id ? classes.me : "")
          }
        >
          {user.rank != null && (
            <span>
              {user.rank}
              <sup>{ordinal_suffix_of(user.rank)}</sup>
              {this.renderTrend(user.rank, user.lastRank)}
            </span>
          )}
        </TableCell>

        <TableCell
          className={
            classes.name +
            " " +
            (user.user_id == State.user.id ? classes.me : "")
          }
        >
          {user.username || <Skeleton variant="rect" height={25} />}
          &nbsp;
          {this.isFinished ? (
            <>
              {user.rank == 1 && "🥇"}
              {user.rank == 2 && "🥈"}
              {user.rank == 3 && "🥉"}
            </>
          ) : null}
          {/* {user.totalWins == 1230 && <CheckIcon className={classes.check} />} */}
        </TableCell>
        {/* <TableCell
          align="right"
          className={user.user_id == State.user.id ? classes.me : ""}
        >
          {user.username ? (
            <span className={classes.diff}>{user.lastScore - user.score}</span>
          ) : (
            ""
          )}
        </TableCell> */}
        <TableCell
          align="right"
          className={user.user_id == State.user.id ? classes.me : ""}
        >
          {user.username ? (
            this.isLocked ? (
              <>
                {this.renderTrend(user.score, user.lastScore, true)}
                <span>{user.score || 0}</span>&nbsp;
                {/* <sup className={classes.diff}>
                  {user.lastScore - user.score}
                </sup> */}
              </>
            ) : (
              "TBD"
            )
          ) : (
            <Skeleton variant="rect" height={25} />
          )}

          {/* <TextField
            margin="dense"
            disabled={State.isLocked}
            InputProps={{
              classes: {
                root: classes.filledInput
              }
            }}
            inputProps={{
              size: 2,
              style: { borderRadius: 0, textAlign: "right" }
            }}
            classes={{ root: classes.input }}
            placeholder={user.placeholderWins}
            value={user.wins}
            hiddenLabel
            variant="filled"
          /> */}
        </TableCell>
        {/* <TableCell align="right">{State.totalGames  - user.wins}</TableCell>
        <TableCell align="right">
          {((user.wins / State.totalGames ) * 100).toFixed(1)}
        </TableCell> */}
        {/* <TableCell checkbox>
          <Checkbox
            onClick={event => this.handleClick(event, team.id)}
            checked={isSelected}
          />
        </TableCell> */}
      </TableRow>
    );
  }
}

export default withStyles(styles)(observer(UserRow));
