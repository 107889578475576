import React, { useCallback, useEffect, useState } from "react";
import {
  LinearProgress,
  Paper,
  withStyles,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginBottom: 0,
    border: "2px solid #3f51b5",
  },
});

export default withStyles(styles)(({ classes }) => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const subscribe = useCallback(() => {
    axios
      .put(
        "https://api.sendgrid.com/v3/marketing/contacts",
        {
          list_ids: ["666964b3-4757-4b23-aff8-61f03e769145"],
          contacts: [
            {
              email: email,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer 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",
          },
        }
      )
      .then(() => setSubscribed(true));
  }, [email]);
  return (
    <Paper className={classes.paper}>
      {subscribed ? (
        <Typography variant="h6">Thanks for subscribing!</Typography>
      ) : (
        <div>
          <Typography variant="h6">Don't miss next season!</Typography>
          <Typography variant="body2" style={{ marginBottom: 8 }}>
            Enter your email below to get notified when the 2023-2024 season
            opens.
          </Typography>

          <TextField
            autoFocus
            // error={this.state.error}
            margin="dense"
            id="name"
            label="Email address"
            type="email"
            InputProps={{
              style: {
                fontSize: 18,
              },
            }}
            style={{ minWidth: 300, verticalAlign: "middle" }}
            // value={this.state.name}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // onChange={this.handleChange}
            // fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            style={{ verticalAlign: "middle", marginLeft: 8 }}
            onClick={subscribe}
          >
            Subscribe
          </Button>
        </div>
      )}
    </Paper>
  );
});
