// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  withStyles,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../components/Auth";
import State from "../State";
import Page from "../components/Page";

class CreateLeague extends React.Component {
  state = {
    name: "",
  };

  handleRequestClose = () => {
    State.leagues.dialogOpen = 0;
  };

  createLeague = () => {
    State.createLeague(this.state.name, (leagueId) => {
      this.handleRequestClose();
      this.props.history.push("/leagues/" + leagueId);
    });
  };

  handleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  render() {
    return (
      <Dialog
        open={State.leagues.dialogOpen == 1}
        onRequestClose={this.handleRequestClose}
      >
        <DialogTitle>Create League</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To get started, enter a name for your league.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="League Name"
            type="text"
            value={this.state.name}
            onChange={this.handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.createLeague} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class JoinLeague extends React.Component {
  state = {
    code: "",
  };

  handleRequestClose = () => {
    State.leagues.dialogOpen = 0;
  };

  joinLeague = () => {
    State.joinLeague(this.state.code, (leagueId) => {
      console.log("leagueId", leagueId);
      this.handleRequestClose();
      this.props.history.push("/leagues/" + leagueId);
    });
  };

  handleChange = (event) => {
    this.setState({ code: event.target.value });
  };

  render() {
    return (
      <Dialog
        open={State.leagues.dialogOpen == 2}
        onRequestClose={this.handleRequestClose}
      >
        <DialogTitle>Join League</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the code that was provided to you by an active member.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="League Code"
            type="text"
            value={this.state.code}
            onChange={this.handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.joinLeague} color="primary">
            Join
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  paper: {
    overflowX: "auto",
  },
  division: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingLeft: "24px",
    fontWeight: "bold",
  },
});

class Leagues extends Component {
  state = {
    leagues: [],
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  init = autorun(() => {
    if (State.user.id) {
      State.getLeagues((leagues) => {
        this.setState({
          leagues: leagues,
        });
      });
    }
  });

  renderLeagues = () => {
    return this.state.leagues.map((league) => {
      return (
        <div>
          <Link to={`/leagues/${league.code}`}>
            <Typography type="subheading">{league.name}</Typography>
          </Link>
        </div>
      );
    });
  };

  render() {
    return (
      <Page>
        <Paper>
          {/* <Typography type="subheading" style={{textAlign:"left"}}><b>My Leagues</b></Typography> */}

          <br />
          {State.user.loaded && !State.user.loggedIn && (
            <div>
              <Typography type="headline">Sign in to join a league</Typography>
              <Auth></Auth>{" "}
            </div>
          )}

          {State.user.loggedIn && (
            <div>
              <Button onClick={() => (State.leagues.dialogOpen = 1)}>
                <Typography type="title">Create a League</Typography>
              </Button>
              <br />
              - or -
              <br />
              <Button onClick={() => (State.leagues.dialogOpen = 2)}>
                <Typography type="title">Join a League</Typography>
              </Button>
              <br />
              <br />
              {this.renderLeagues()}
              <br />
              <CreateLeague
                history={this.props.history}
                open={State.leagues.dialogOpen == 1}
              />
              <JoinLeague
                history={this.props.history}
                open={State.leagues.dialogOpen == 2}
              />
            </div>
          )}
        </Paper>
      </Page>
    );
  }
}

export default withStyles(styles)(observer(Leagues));
