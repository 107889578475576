import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles, Dialog } from "@material-ui/core";
import { observer } from "mobx-react";
import State from "../../State";
import Picks from "../Picks";

const styles = (theme) => ({});

class UserModal extends React.Component {
  handleClose = () => {
    State.openModals.user = null;
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog open={State.openModals.user != null} onClose={this.handleClose}>
        <div style={{ width: 600, height: 600, overflow: "auto" }}>
          <Picks
            userId={State.openModals.user}
            username={State.openModals.username}
            seasonId={State.openModals.seasonId}
          />
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(observer(UserModal));
