import React from "react";
import State from "../State";
import { LinearProgress, Paper, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import Card from "./Card";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const styles = theme => ({
  paper: {
    padding: 8
  },
  welcomeHeader: {
    textAlign: "left",
    padding: 16,
    marginBottom: 16
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  saving: {
    opacity: 0.3,
    fontWeight: "normal"
  },
  saved: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    fontSize: 18,
    marginLeft: 4
  }
});

class Progress extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card
        title="Progress"
        titleActions={
          State.user.loggedIn && (
            <div className={classes.saving}>
              {State.savingPicks ? (
                "Saving..."
              ) : (
                <div className={classes.saved}>
                  Saved{" "}
                  <CheckCircleIcon className={classes.icon}></CheckCircleIcon>
                </div>
              )}
            </div>
          )
        }
        sticky
      >
        {State.winStats[1] > 0 && (
          <div>
            <div style={{ position: "relative" }}>
              <LinearProgress
                style={{ height: "30px" }}
                variant="determinate"
                value={State.winStats[2]}
              />
              <div
                style={{
                  color: "white",
                  top: "7px",
                  left: "0",
                  right: "0",
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "absolute"
                }}
              >
                {State.winStats[0]} / {State.winStats[1]}
              </div>
            </div>

            <div style={{ margin: "10px" }}>
              {State.winStats[0] == State.winStats[1]
                ? "You are balanced!"
                : (State.winStats[1] - State.winStats[0] > 0
                    ? "Add"
                    : "Remove") +
                  " " +
                  Math.abs(State.winStats[1] - State.winStats[0]) +
                  " wins to balance."}
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(observer(Progress));
