import React from "react";
import State from "../State";
import {
  LinearProgress,
  Paper,
  withStyles,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react";
import Card from "./Card";

const styles = theme => ({
  paper: {
    padding: 8
  },
  welcomeHeader: {
    textAlign: "left",
    padding: 16,
    marginBottom: 16
  }
});

class Welcome extends React.Component {
  render() {
    const { classes, header, title, body } = this.props;
    return (
      <Paper className={classes.paper + " " + classes.welcomeHeader}>
        <Typography variant="h6">{title}</Typography>
        {body}
      </Paper>
    );
  }
}

export default withStyles(styles)(observer(Welcome));
