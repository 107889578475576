// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React from "react";
import State from "../../State";

export default class EditLeagueModal extends React.Component {
  state = {
    id: "",
    name: "",
    description: "",
    website: "",
    avatarURL: "",
    bannerURL: "",
  };

  handleRequestOpen = () => {
    this.setState(State.activeLeague);
  };

  handleRequestClose = () => {
    this.setState({
      error: "",
      saving: false,
    });
    State.closeModals();
  };

  updateLeague = () => {
    this.setState({
      saving: true,
    });
    State.updateLeague(State.user.id, this.state.id, this.state, (res) => {
      this.setState({ saving: false });
      if (res && res.data && res.data[0].code) {
        console.log("leagueId", res.data[0].code);
        this.handleRequestClose();
        this.props.history.push("/leagues/" + res.data[0].code);
      }
    });
  };

  handleChange = (name, event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <Dialog
        open={State.leagues.dialogOpen == 5}
        onEnter={this.handleRequestOpen}
        onRequestClose={this.handleRequestClose}
      >
        <DialogTitle>Edit League</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Enter the code that was provided to you by an active member.
          </DialogContentText> */}
          <TextField
            error={this.state.error}
            margin="dense"
            label="League Name"
            type="text"
            value={this.state.name || ""}
            onChange={(e) => this.handleChange("name", e)}
            fullWidth
          />
          <TextField
            error={this.state.error}
            margin="dense"
            label="League Description"
            type="text"
            value={this.state.description || ""}
            onChange={(e) => this.handleChange("description", e)}
            fullWidth
          />
          <TextField
            error={this.state.error}
            margin="dense"
            label="League Website URL"
            type="text"
            value={this.state.website || ""}
            onChange={(e) => this.handleChange("website", e)}
            fullWidth
          />
          <TextField
            error={this.state.error}
            margin="dense"
            label="League Avatar URL"
            type="text"
            value={this.state.avatarURL || ""}
            onChange={(e) => this.handleChange("avatarURL", e)}
            fullWidth
          />
          <TextField
            error={this.state.error}
            margin="dense"
            label="League Banner URL"
            type="text"
            value={this.state.bannerURL || ""}
            onChange={(e) => this.handleChange("bannerURL", e)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleRequestClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.updateLeague}
            color="primary"
            disabled={this.state.saving}
          >
            {this.state.saving ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
