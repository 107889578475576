import { createMuiTheme } from "@material-ui/core";

// const ProximaNova = {
//   fontFamily: "Proxima Nova",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
//           url(fonts/ProximaNova.otf) format('otf')
//         `
// };

// const ProximaNovaSemiBold = {
//   fontFamily: "Proxima Nova",
//   fontStyle: "bold",
//   fontDisplay: "swap",
//   fontWeight: 600,
//   src: `
//           url(fonts/ProximaNovaSemiBold.otf) format('otf')`
// };
const theme = createMuiTheme({
  typography: {
    fontFamily: "Proxima Nova, Arial",
    fontSize: 18,
    button: {
      textTransform: "none"
    },
    tab: {
      fontSize: "4rem"
    }
  }
  //   overrides: {
  //     MuiCssBaseline: {
  //       "@global": {
  //         "@font-face": [ProximaNova]
  //       }
  //     }
  //   }
});

export default theme;
