// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import State from "../../State";
import { useTheme } from "@material-ui/core/styles";

const ScoreFAQ = () => {
  const [seasonId, setSeasonId] = useState(0);

  const handleRequestOpen = () => {
    setSeasonId(State.activeLeague.seasonId);
  };

  const handleRequestClose = () => {
    State.closeModals();
  };

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={State.leagues.dialogOpen == 4}
      onEnter={handleRequestOpen}
      onClose={handleRequestClose}
      onRequestClose={handleRequestClose}
    >
      <DialogTitle>How Scoring Works</DialogTitle>
      <DialogContent>
        {seasonId == State.currentSeason ? (
          <DialogContentText>
            Scores are calculated by taking the sum of the differences between
            the user's predictions and the current FiveThirtyEight RAPTOR
            projections for each team.
            <p>
              At the end of the season, the final score will be determined by
              taking the sum of the differences between the user's predictions
              and each team's final record. Lowest score wins!
            </p>
          </DialogContentText>
        ) : (
          <DialogContentText>
            Given the shortened season, final scores were calculated by taking
            each NBA team's regular season win percentages (including bubble
            games) and extrapolating wins for an 82-game season based on those.
            <p>
              Those wins were then compared to each user's team predictions. The
              sum of those differences determine the final score, with the
              lowest score winning.
            </p>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="primary">
          Okay!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScoreFAQ;
