// @flow weak
/* eslint-disable react/no-multi-comp */

import React from "react";
import CreateLeagueModal from "./CreateLeagueModal";
import JoinLeagueModal from "./JoinLeagueModal";
import EditLeagueModal from "./EditLeagueModal";

import UserModal from "./UserModal";
import ScoreFAQ from "./ScoreFAQ";

import { withRouter } from "react-router-dom";

class ModalManager extends React.Component {
  render() {
    return (
      <>
        <JoinLeagueModal {...this.props} />
        <CreateLeagueModal {...this.props} />
        <EditLeagueModal {...this.props} />
        <UserModal {...this.props} />
        <ScoreFAQ {...this.props} />
      </>
    );
  }
}

export default withRouter(ModalManager);
