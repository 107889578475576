import AppBar from "@material-ui/core/AppBar";
import { Button, Tabs, Tab, Toolbar, withStyles } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import State from "../State";
import { observer } from "mobx-react";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import withWidth from "@material-ui/core/withWidth";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  appBar: {
    background: "#000",
  },
  root: {
    width: "100%",
  },
  flex: {
    flex: 1,
  },
  tabs: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logout: {
    marginRight: 6,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logo: {
    marginLeft: 16,
    marginRight: 16,
    height: 36,
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
    },
  },
  drawerHeader: {
    // display: "flex",
    // alignItems: "center",
    padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar
    // justifyContent: "flex-end"
  },
  menuButton: {
    alignSelf: "flex-end",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
});

const ButtonAppBar = observer(({ classes, history, location }) => {
  const tabs = [
    {
      label: "Predictions",
      link: "picks",
    },
    {
      label: "Standings",
      link: "leagues",
    },
    // {
    //   label: "Blog",
    //   link: "blog"
    // }
  ];
  let path = location.pathname.split("/")[1];
  if (!path) {
    path = "picks";
  }

  const handleChange = (event, value) => {
    console.log("handle change", value);
    if (value == "picks") {
      history.push("/");
    } else {
      history.push("/" + value);
    }

    // State.location = value;
  };

  return (
    <div>
      <AppBar className={classes.appBar} position="static">
        <Toolbar disableGutters variant="dense" className="container">
          <img src="/img/logo.png" className={classes.logo} />
          <Tabs className={classes.tabs} value={path} onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab value={tab.link} label={tab.label} />
            ))}
          </Tabs>
          {State.user.loggedIn && (
            <Button
              color="inherit"
              className={classes.logout}
              onClick={State.logout}
            >
              Logout
            </Button>
          )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={State.toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        // className={classes.drawer}
        // variant="persistent"
        onClose={State.toggleDrawer}
        anchor="right"
        open={State.drawer.open}
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
      >
        <AppBar className={classes.appBar} position="static">
          <div>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={State.toggleDrawer}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </AppBar>
        <Divider />
        <Divider />
        <List>
          {tabs.map((tab, index) => (
            <ListItem
              onClick={(e) => {
                handleChange(e, tab.link);
                State.toggleDrawer();
              }}
              button
              selected={path == tab.link}
            >
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={tab.label} />
            </ListItem>
          ))}
          {State.user.loggedIn && (
            <ListItem
              onClick={() => {
                State.logout();
                State.toggleDrawer();
              }}
              button
            >
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={"Logout"} />
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
});

export default withStyles(styles)(withRouter(ButtonAppBar));
