import React from "react";
import State from "../State";
import {
  LinearProgress,
  Paper,
  withStyles,
  Typography,
  Divider
} from "@material-ui/core";
import { observer } from "mobx-react";

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    paddingTop: 0,
    marginBottom: theme.spacing(2)
  },
  title: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    height: 45,
    marginBottom: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.divider
  },
  sticky: {
    top: 16,
    position: "sticky",
    zIndex: 100
  }
});

class Card extends React.Component {
  render() {
    const { classes, children, title, titleActions, sticky } = this.props;
    return (
      <Paper className={classes.paper + " " + (sticky ? classes.sticky : "")}>
        {title && (
          <Typography
            className={classes.title}
            variant="subtitle2"
            align="left"
          >
            <b>{title}</b>
            {titleActions}
          </Typography>
        )}
        <div>{children}</div>
      </Paper>
    );
  }
}

export default withStyles(styles)(Card);
