// @flow weak
/* eslint-disable react/no-multi-comp */

import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import keycode from "keycode";
import { computed, extendObservable } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Auth from "../components/Auth";
import Progress from "../components/Progress";
import ExpertPicks from "../components/ExpertPicks";

import UserRow from "../components/UserRow";
import Skeleton from "@material-ui/lab/Skeleton";

import State from "../State";

const styles = (theme) => ({
  header: {
    margin: 16,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  paper: {
    overflowX: "auto",
  },
  division: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingLeft: "16px",
    fontWeight: "bold",
    borderBottom: 0,
    height: 45,
    whiteSpace: "nowrap",
  },
  tabs: {
    marginTop: -8,
    marginRight: -8,
    height: 50,
  },
  table: {
    tableLayout: "fixed",
    overflowWrap: "break-word",
  },
  rank: {
    width: 80,
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      width: "40px !important",
    },
  },
});

const scoreFAQ = (e) => {
  e.stopPropagation();
  State.openModal(4);
};

const columnData = [
  {
    id: "rank",
    numeric: false,
    disablePadding: false,
    // label: "Rank",
    className: "rank",
    align: "center",
  },
  { id: "name", numeric: false, disablePadding: false, label: "User" },
  // {
  //   id: "finished",
  //   align: "center",
  //   numeric: false,
  //   disablePadding: false,
  //   label: ""
  // },
  // {
  //   id: "change",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Daily Delta"
  // },
  {
    id: "score",
    numeric: true,
    disablePadding: false,
    label: (
      <div>
        Projected Score
        <Button
          onClick={scoreFAQ}
          style={{
            lineHeight: "12px",
            minWidth: "0px",
            minHeight: "0px",
          }}
        >
          ?
        </Button>
      </div>
    ),
  },
  //   { id: "losses", numeric: true, disablePadding: false, label: "Losses" },
  //   { id: "pct", numeric: true, disablePadding: false, label: "Pct" }
];

class Standings extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    selected: [],
    data: {},
  };

  render() {
    const { users, state, classes, isFinished } = this.props;
    return (
      <Table className={classes.table} size="small">
        <EnhancedTableHead
          classes={classes}
          //   numSelected={selected.length}
          //   order={order}
          //   orderBy={orderBy}
          isFinished={isFinished}
          state={state}
          onSelectAllClick={this.handleSelectAllClick}
          //   onRequestSort={this.handleRequestSort}
        />
        <TableBody>
          {!state.loaded ? (
            <UserRow user={{ id: "skeleton" }} />
          ) : users.length ? (
            users
              .sort((a, b) => a.score - b.score)
              .map((user, index) => {
                // let team = State.userTeamPicks.get(teamId);

                // const isSelected = this.isSelected(teamId);
                let res = [];
                // if (this.state.orderBy == "name") {
                //   if (!this.last || team.division_id != this.last.division_id) {
                //     res.push(
                //       <TableRow key={team.division_id}>
                //         <TableCell className={classes.division}>
                //           {State.divisions[team.division_id]} Division
                //         </TableCell>
                //       </TableRow>
                //     );
                //   }
                //   this.last = team;
                // }
                res.push(
                  <UserRow
                    key={user.user_id}
                    // isSelected={isSelected}
                    state={state}
                    index={index}
                    handleKeyDown={this.handleKeyDown}
                    updatePick={this.updatePick}
                    user={user}
                    isFinished={isFinished}
                  />
                );
                return res;
              })
          ) : (
            <div
              style={{ marginTop: 16, marginBottom: 8, textAlign: "center" }}
            >
              No users found.
            </div>
          )}
        </TableBody>
      </Table>
    );
  }
}

class EnhancedTableHead extends Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      state,
      isFinished,
      classes,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map((column) => {
            return (
              <TableCell
                style={{
                  width: column.width,
                }}
                className={column.className ? classes[column.className] : ""}
                key={column.id}
                align={column.align || (column.numeric ? "right" : "left")}
              >
                {column.label && (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.id == "score" ? (
                      <div>
                        {isFinished ? "Final" : "Projected"} Score
                        <Button
                          onClick={scoreFAQ}
                          style={{
                            lineHeight: "12px",
                            minWidth: "0px",
                            minHeight: "0px",
                          }}
                        >
                          ?
                        </Button>
                      </div>
                    ) : (
                      column.label
                    )}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          }, this)}
          {/* <TableCell checkbox>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < 5}
                checked={numSelected === 5}
                onChange={onSelectAllClick}
              />
            </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(observer(Standings));
