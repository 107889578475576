import { Button, Typography, withStyles } from "@material-ui/core";
import React from "react";
import State from "../State";
import { computed, extendObservable } from "mobx";
import Skeleton from "@material-ui/lab/Skeleton";

import { observer } from "mobx-react";
import bbobReactRender from "@bbob/react/es/render";
import presetReact from "@bbob/preset-react";

const styles = (theme) => ({
  avatar: {
    zIndex: 1,
    borderRadius: "50%",
    width: 120,
    height: 120,
    marginRight: 24,
    alignSelf: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 60,
    },
  },
  nameWithAvatar: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      paddingTop: 16,
    },
  },
  description: {},
  descriptionWithAvatar: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      marginLeft: -80,
      marginTop: 32,
    },
  },
  website: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
  },
  websiteWithAvatarAndDescription: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      marginLeft: -80,
    },
  },
});

class LeagueBanner extends React.Component {
  constructor(props) {
    super(props);
    extendObservable(this, {
      get numUsers() {
        return (this.props.users || []).length;
      },
    });
  }

  render() {
    const { league, isAdmin, classes } = this.props;

    let description = null;

    if (league.description)
      description = bbobReactRender(league.description, presetReact());

    return (
      <div
        style={{
          height: "auto",
          margin: "-8px -8px 0px -16px",
          display: "flex",
          alignItems: "center",
          paddingLeft: 16,
          paddingTop: 16,
          paddingBottom: 16,
          justifyContent: "flex-start",
          position: "relative",
          color: league.bannerURL ? "white" : "black",
        }}
      >
        <div
          style={{
            width: "100%",
            heigt: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            filter: "brightness(.4)",
            background: `url(${league.bannerURL}) center center / cover no-repeat`,
          }}
        ></div>
        {isAdmin ? (
          <Button
            onClick={() => {
              State.openModal(5);
            }}
            style={{
              color: league.bannerURL ? "white" : "black",
              zIndex: 2,
              position: "absolute",
              top: 8,
              right: 8,
              fontSize: 14,
            }}
          >
            Edit League Info
          </Button>
        ) : null}
        {league.avatarURL ? (
          <img className={classes.avatar} src={league.avatarURL} />
        ) : null}
        <div
          style={{
            zIndex: 1,
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            className={league.avatarURL ? classes.nameWithAvatar : ""}
            style={{
              marginTop: league.bannerURL ? "-8px" : "0px",
              textShadow: league.bannerURL ? "2px 2px rgba(0,0,0,.5)" : "",
            }}
          >
            {league.name || " "}
          </Typography>
          {league.description && (
            <div
              className={
                classes.description +
                (league.avatarURL ? " " + classes.descriptionWithAvatar : "") +
                " league-description"
              }
              style={{
                marginBottom: 6,
                paddingRight: 8,
                // textShadow: league.bannerURL ? "2px 2px rgba(0,0,0,.5)" : "",
              }}
            >
              {description}
            </div>
          )}
          {league.website && (
            <div
              className={
                classes.website +
                (league.avatarURL && league.description
                  ? " " + classes.websiteWithAvatarAndDescription
                  : "")
              }
            >
              <a
                target="_blank"
                href={league.website}
                style={{ color: league.bannerURL ? "white" : "black" }}
              >
                {league.website}
              </a>{" "}
            </div>
          )}
          {/* <div>
            Started {league.created}. {this.numUsers} active users.
          </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(observer(LeagueBanner));
