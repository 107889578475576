import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Input, List, Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../components/Auth";
import State from "../State";
import Standings from "../components/Standings";

export default class AppBreadcrumbs extends React.Component {
  render() {
    const { trail } = this.props;
    return (
      <Paper elevation={0}>
        <Breadcrumbs aria-label="breadcrumb">
          {trail.map(link =>
            link.link ? (
              <Link color="inherit" to={link.link}>
                {link.label}
              </Link>
            ) : (
              <Typography color="textPrimary">{link.label}</Typography>
            )
          )}
        </Breadcrumbs>
      </Paper>
    );
  }
}
