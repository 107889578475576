import React from "react";
import State from "../State";
import {
  LinearProgress,
  Paper,
  withStyles,
  Button,
  IconButton,
  Input
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { observer } from "mobx-react";
import Card from "./Card";
import Auth from "../components/Auth";

import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton
} from "react-share";

const styles = theme => ({
  button: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1)
    }
  }
});

class JoinLeague extends React.Component {
  joinLeague = () => {
    const { league, loadLeague } = this.props;
    State.joinLeague(league.code, () => {
      loadLeague();
    });
  };

  render() {
    const { classes, league } = this.props;
    return (
      <Card title="Want to Join?">
        {State.user.loggedIn ? (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={this.joinLeague}
          >
            Join {league.name}
          </Button>
        ) : (
          <Auth />
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(observer(JoinLeague));
