import React from "react";
import State from "../State";
import { LinearProgress, Paper, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import Card from "./Card";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const styles = theme => ({
  paper: {
    padding: 8
  },
  welcomeHeader: {
    textAlign: "left",
    padding: 16,
    marginBottom: 16
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  saving: {
    opacity: 0.3,
    fontWeight: "normal"
  },
  saved: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    fontSize: 18,
    marginLeft: 4
  },
  score: {
    fontSize: 36,
    fontWeight: "bold"
  }
});

class Score extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card title="Total Score" sticky>
        <div className={classes.score}></div>
      </Card>
    );
  }
}

export default withStyles(styles)(observer(Score));
