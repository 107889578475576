import React from "react";
import State from "../State";
import {
  LinearProgress,
  Paper,
  withStyles,
  Button,
  IconButton,
  Input,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { observer } from "mobx-react";
import Card from "./Card";

import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton
} from "react-share";

const styles = theme => ({
  button: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1)
    }
  }
});

class ShareLeague extends React.Component {
  render() {
    const { classes, league } = this.props;
    return (
      <Card title="Share League">
        {/* <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                //   onChange={handleChange("checkedA")}
                value="checkedA"
              />
            }
            label="Require Password"
          />
        </FormGroup> */}
        {/* Share this code: */}
        <Input
          fullWidth
          value={
            league.code ? "http://www.wintotals.com/leagues/" + league.code : ""
          }
        />
        {/* <FacebookShareButton url={"http://www.wintotals.com"}>
          <Button>Share on Facebook</Button>
        </FacebookShareButton>
        <TwitterShareButton url={"http://www.wintotals.com"}>
          <Button>Share on Twitter</Button>
        </TwitterShareButton> */}
      </Card>
    );
  }
}

export default withStyles(styles)(observer(ShareLeague));
