import React from "react";
import State from "../State";
import { LinearProgress, Paper, withStyles, Button } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { observer } from "mobx-react";
import Card from "./Card";

const styles = (theme) => ({
  button: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
});

class ExpertPicks extends React.Component {
  loadPicks = (userId) => {
    State.getAllUserTeamPicks(userId);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card title="Auto-fill Predictions with...">
        <Button
          disabled={State.league.isLocked}
          onClick={() => this.loadPicks("qEih1pyhnTgPlv4clVPK5PLRzmf2")}
          variant="contained"
          fullWidth
          color="primary"
          className={classes.button}
        >
          2023-2024 Wins
        </Button>
        <Button
          disabled={true || State.league.isLocked}
          onClick={() => this.loadPicks("Vqy6EFnjQ6NsUwiKDO192AFh56A2")}
          variant="contained"
          fullWidth
          color="secondary"
          className={classes.button}
        >
          Bill Simmons Over/Unders
        </Button>
        {false && (
          <Button
            disabled={State.league.isLocked}
            onClick={() => this.loadPicks("yi8yXtQ0EMSml4iglEv3aH9DB4L2")}
            variant="contained"
            fullWidth
            color="secondary"
            className={classes.button}
          >
            RAPTOR Forecast (FiveThirtyEight)
          </Button>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(observer(ExpertPicks));
